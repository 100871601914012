<template>
    <div>
        <div class="top-icon font12 scan-top-title">
            <router-link :to="{ name: 'MainLayoutHome' }" class="bg-2">
                <i class="el-icon-arrow-left"></i> Назад
            </router-link>
            <span>QR Codni o'qting</span>
            <el-button @click="logout()" type="danger" class="el-icon-unlock color-pora">
                {{ $t('message.Exit') }}
            </el-button>
        </div>
        <div class="scan-full">
            <div class="scan">
                <el-input placeholder="Please input" v-model="input"></el-input>
                <img
                    class="scan-img"
                    src="./../../public/img/qr-code-scan.png"
                    alt=""
                />
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            input: "",
        };
    },
    methods: {        
        logout() {
            this.$confirm('Вы уверены, что хотите выйти?', 'Подтверждение', {
                    confirmButtonText: 'Да',
                    cancelButtonText: 'Нет',
                    type: 'warning'
                }).then(async () => {
                    await this.$store.dispatch("auth/logout");
                    this.$router.push(`/login?redirect=${this.$route.fullPath}`);
                }).catch(() => {
                
                });
        },
    },
};
</script>